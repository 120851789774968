import React from "react"

const Giphy = ({ html }) => {
  return (
    <div
      className="gatsby-embed-giphy"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default Giphy
