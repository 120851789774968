import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import HubspotForm from "../../components/hubspot-form"
import CodeBlock from "../blocks/code-block"
import YouTube from "../blocks/youtube"
import Twitter from "../blocks/twitter"
import Giphy from "../blocks/giphy"
import Pullquote from "../blocks/pullquote"
import CloudCallout from "../blocks/cloud-callout"

/* Content Block */
const graphQLTypeToBlockType = {
  WpPost_Flexiblecontent_Blocks_CodeBlock: `code`,
  WpPost_Flexiblecontent_Blocks_CloudCallout: `cloud`,
  WpPost_Flexiblecontent_Blocks_Embed: `embed`,
  WpPost_Flexiblecontent_Blocks_HubspotForm: `hubspotForm`,
  WpPost_Flexiblecontent_Blocks_Image: `image`,
  WpPost_Flexiblecontent_Blocks_Pullquote: `pullquote`,
  WpPost_Flexiblecontent_Blocks_RichText: `text`,
}

const getBlockType = block => graphQLTypeToBlockType[block.__typename]

const FlexibleContentBlock = ({ block }) => {
  const type = getBlockType(block)

  if (type === `code`) {
    return (
      <CodeBlock language={block.language} title={block.filename}>
        {block.code}
      </CodeBlock>
    )
  }

  if (type === `cloud`) {
    return <CloudCallout>{block.text}</CloudCallout>
  }

  if (type === `embed`) {
    switch (block.embedType) {
      case "YouTube":
        return <YouTube html={block.embedHtml} />
      case "Twitter":
        return <Twitter html={block.embedHtml} />
      case "Giphy":
        return <Giphy html={block.embedHtml} />
      default:
        return null
    }
  }

  if (type === `hubspotForm`) {
    return (
      <HubspotForm
        title={block.name}
        portalId={block.portalId}
        formId={block.formId}
        sfdcCampaignId={block.salesforceId}
      />
    )
  }

  if (type === `image`) {
    if (!block.image?.localFile) {
      return null
    }

    return (
      <figure>
        {block.image.localFile.extension === `gif` ? (
          <img
            alt={block.image.altText}
            src={block.image.localFile.publicURL}
          />
        ) : (
            <Img
              alt={block.image.altText}
              fluid={block.image.localFile.childImageSharp.fluid}
            />
          )}
        <figcaption>{block.image?.caption}</figcaption>
      </figure>
    )
  }

  if (type === `pullquote`) {
    return (
      <Pullquote citation={block.citation}>
        <div dangerouslySetInnerHTML={{ __html: block.pullquoteText }} />
      </Pullquote>
    )
  }

  if (type === `text`) {
    return <div dangerouslySetInnerHTML={{ __html: block.richText }}></div>
  }

  return null
}

export default FlexibleContentBlock

export const flexibleContentBlocksFragment = graphql`
  fragment FlexibleContentBlocks on WpPost {
    flexibleContent {
      blocks {
        __typename
        ... on WpPost_Flexiblecontent_Blocks_RichText {
          richText
        }
        ... on WpPost_Flexiblecontent_Blocks_Pullquote {
          citation
          pullquoteText
        }
        ... on WpPost_Flexiblecontent_Blocks_Image {
          image {
            id
            altText
            localFile {
              publicURL
              extension
              childImageSharp {
                id
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        ... on WpPost_Flexiblecontent_Blocks_Embed {
          embedType
          embedUrl
          embedHtml
        }
        ... on WpPost_Flexiblecontent_Blocks_CodeBlock {
          code
          language
          filename
        }
        ... on WpPost_Flexiblecontent_Blocks_CloudCallout {
          text
        }
        ... on WpPost_Flexiblecontent_Blocks_HubspotForm {
          name
          formId
          portalId
          salesforceId
        }
      }
    }
  }
`
