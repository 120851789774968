import React from "react"
import { Link } from "gatsby"
import { LinkButton } from "gatsby-interface"
import { FaTags as TagsIcon } from "react-icons/fa"

const tagsContainerCss = {
  alignItems: `baseline`,
  display: `flex`,
  flexFlow: `row wrap`,
  justifyContent: `space-between`,
}

const tagsCss = theme => ({
  color: theme.colors.text.secondary,
  display: `block`,
  flexBasis: `60%`,
  flexGrow: `1`,
  fontSize: theme.fontSizes[0],
  fontStyle: "normal",
  marginBottom: theme.space[2],
  marginTop: theme.space[6],
})

const Tags = ({ tags }) => {
  if (!tags.length > 0) {
    return null
  }

  return (
    <div css={tagsContainerCss}>
      <em css={tagsCss}>
        {tags.length && "Tagged with "}
        {tags.map((tag, i) => {
          const divider = i < tags.length - 1 && <span>{`, `}</span>

          return (
            <span key={tag.name}>
              <Link to={`/blog/tags/${tag.slug}`}>{tag.name}</Link>
              {divider}
            </span>
          )
        })}
      </em>
      <LinkButton
        to={`/blog/tags`}
        size="S"
        variant="GHOST"
        rightIcon={<TagsIcon />}
      >
        View all Tags
      </LinkButton>
    </div>
  )
}

export default Tags
