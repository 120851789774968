import React from "react"

const Twitter = ({ html }) => {
  return (
    <div
      className="gatsby-embed-twitter"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default Twitter
