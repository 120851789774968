import React from "react"
import { Link, graphql } from "gatsby"
import { Avatar } from "gatsby-interface"

const wrapperCss = theme => ({
  display: `flex`,
  fontSize: theme.fontSizes[1],
  alignItems: "center",
  [theme.mediaQueries.desktop]: {
    flexDirection: "column",
    alignItems: "start",
  },
})

const nameCss = theme => ({
  color: theme.colors.gatsby,
  display: `inline-block`,
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.bold,
  lineHeight: theme.lineHeights.dense,
  textDecoration: `none`,
  [theme.mediaQueries.desktop]: {
    marginTop: theme.space[2],
  },
})

const bioCss = theme => ({
  color: theme.colors.text.secondary,
  lineHeight: theme.lineHeights.dense,
  marginTop: theme.space[1],
})

export const Name = ({ name, slug }) => {
  return (
    <Link to={`/contributors/${slug}`} css={nameCss}>
      {name}
    </Link>
  )
}

const SecondaryContent = ({ content }) => {
  return <div css={bioCss}>{content}</div>
}

export const AuthorAvatar = ({ slug, image, name }) => {
  return (
    <Link
      to={`/contributors/${slug}`}
      css={theme => ({
        display: "block",
        marginRight: theme.space[5],
        lineHeight: theme.lineHeights.solid,
      })}
    >
      <Avatar src={image} label={name} size="L" />
    </Link>
  )
}

// would be nice to get <Avatar> working with gatsby-image
const Author = ({ name, date, image, slug }) => {
  return (
    <section css={wrapperCss}>
      {image && (
        <div css={{ flex: `0 0 auto` }}>
          <AuthorAvatar slug={slug} image={image} name={name} />
        </div>
      )}
      <div css={{ flex: `1 1 auto` }}>
        <Name slug={slug} name={name} />
        <SecondaryContent content={date} />
      </div>
    </section>
  )
}

export default Author

export const AuthorInformationFragment = graphql`
  fragment AuthorInformation on WpUser {
    name
    slug
    authorInfo {
      twitter
      bio
      avatar {
        localFile {
          childImageSharp {
            resize(width: 48, height: 48, quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
