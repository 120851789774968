import React from "react"
import { Link } from "gatsby"
import {
  MdArrowBack as ArrowBackIcon,
  MdArrowForward as ArrowForwardIcon,
} from "react-icons/md"

const navCss = theme => ({
  marginTop: theme.space[10],
  [theme.mediaQueries.phablet]: {
    display: `flex`,
    justifyContent: `space-between`,
    width: `100%`,
  },
})

const linkCss = theme => ({
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.bold,
  lineHeight: theme.lineHeights.dense,
  "&&": {
    textDecoration: `none`,
  },
})

const labelCss = theme => ({
  color: theme.colors.text.secondary,
  fontSize: theme.fontSizes[1],
  fontWeight: `normal`,
  marginBottom: theme.space[3],
  marginTop: theme.space[8],
})

const linkContainerCss = theme => ({
  [theme.mediaQueries.phablet]: {
    marginTop: theme.space[5],
    width: `48%`,
  },
})

const PrevAndNext = ({ prev = null, next = null }) => {
  if (!prev && !next) {
    return null
  }

  return (
    <nav aria-label="pagination" css={navCss}>
      <div css={linkContainerCss}>
        {prev && (
          <Link to={`/blog/${prev.link}`} css={linkCss}>
            <p css={labelCss}>Previous</p>
            <span
              css={{
                display: `inline-flex`,
                marginLeft: `-1.5em`,
              }}
            >
              <ArrowBackIcon
                css={{
                  flexShrink: 0,
                  marginRight: `0.5em`,
                  position: "relative",
                  top: "0.1825em",
                }}
              />
              {prev.title}
            </span>
          </Link>
        )}
      </div>
      <div css={theme => [linkContainerCss(theme), { textAlign: "right" }]}>
        {next && (
          <Link to={`/blog/${next.link}`} css={linkCss}>
            <p css={labelCss}>Next</p>
            <span
              css={{
                display: `inline-flex`,
                marginRight: `-1.5em`,
              }}
            >
              {next.title}
              <ArrowForwardIcon
                css={{
                  flexShrink: 0,
                  marginLeft: `0.5em`,
                  position: "relative",
                  top: "0.1825em",
                }}
              />
            </span>
          </Link>
        )}
      </div>
    </nav>
  )
}

export default PrevAndNext
